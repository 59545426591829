<template>
  <div>
    <p class="title">查看详情</p>
    <div class="content">
      <el-form
        class="form"
        label-position="top"
        label-width="80px"
        :model="formLabelAlign"
      >
        <el-form-item label="店面">
          <el-input v-model="formLabelAlign.shopName" disabled></el-input>
        </el-form-item>
        <el-form-item label="型号">
          <el-input
            v-model="formLabelAlign.deviceFeatureName"
            disabled
          ></el-input>
        </el-form-item>
        <el-form-item label="资产编码">
          <el-input v-model="formLabelAlign.assetCode" disabled></el-input>
        </el-form-item>
        <el-form-item label="IMEI">
          <el-input v-model="formLabelAlign.deviceImei" disabled></el-input>
        </el-form-item>
        <el-form-item label="巡检时间">
          <el-input v-model="formLabelAlign.createTime" disabled></el-input>
        </el-form-item>
        <el-form-item label="巡检单状态">
          <el-select
            clearable
            class="form_item"
            v-model="formLabelAlign.status"
            placeholder="请选择"
            @change="get"
          >
            <el-option
              v-for="item in status"
              :key="item.id"
              :label="item.label"
              :value="item.statusValue"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="巡检地址">
          <el-input
            v-model="formLabelAlign.inspectionUserPosition"
            disabled
          ></el-input>
        </el-form-item>
        <!-- <el-form-item label="添加图片">
          <el-input v-model="formLabelAlign.type" disabled></el-input>
        </el-form-item> -->

        <el-form-item label="添加图片">
          <!-- <el-upload
            ref="upload"
            class="avatar-uploader"
            action=""
            :limit="3"
            :file-list="fileLists"
            :auto-upload="false"
            list-type="picture-card"
            :on-exceed="handleExceed"
            :on-change="handleChange"
            :on-remove="handleRemove"
            :show-file-list="true"
            disabled
          >
            <i class="el-icon-plus avatar-uploader-icon"></i>
          </el-upload> -->
          <div v-for="item in fileLists" :key="item">
            <img :src="item" />
          </div>
        </el-form-item>
      </el-form>
    </div>
    <el-button @click="cancel" class="button_cancel buttons">取消</el-button>
    <el-button class="button_preserve" @click="assetEdits">保存</el-button>
  </div>
</template>

<script>
import { assetEdit } from "../../../api/abnormalAdmin/particulars";
export default {
  name: "particulars",
  data() {
    return {
      bannerArr: [],
      fileLists: [],
      fileList: [],
      // fileList: [{ url: 'https://fuss10.elemecdn.com/3/63/4e7f3a15429bfda99bce42a18cdd1jpeg.jpeg?imageMogr2/thumbnail/360x360/format/webp/quality/100'}, { url: 'https://fuss10.elemecdn.com/3/63/4e7f3a15429bfda99bce42a18cdd1jpeg.jpeg?imageMogr2/thumbnail/360x360/format/webp/quality/100'}],
      formLabelAlign: {},
      content: [],
      status: [
        {
          id: "1",
          statusValue: "1",
          label: "已处理"
        },
        {
          id: "2",
          statusValue: "2",
          label: "未处理"
        }
      ]
    };
  },
  created() {
    this.formLabelAlign = { ...this.$route.query };
    this.getimg();
  },
  methods: {
    getimg() {
      console.log(process.env.VUE_APP_FILEURL);
      if (this.formLabelAlign.imageUrl) {
        this.fileList.push(
          process.env.VUE_APP_FILEURL + this.formLabelAlign.imageUrl
        );
      }
      if (this.formLabelAlign.imageUrl2) {
        this.fileList.push(
          process.env.VUE_APP_FILEURL + this.formLabelAlign.imageUrl2
        );
      }
      if (this.formLabelAlign.imageUrl3) {
        this.fileList.push(
          process.env.VUE_APP_FILEURL + this.formLabelAlign.imageUrl3
        );
      }
      this.fileLists = this.fileList.map(ele => ({ url: ele }));
      console.log(this.fileLists);
    },
    handleChange(file, fileList) {
      console.log(file);
      console.log(fileList);
      let param = {
        url: file.url,
        uid: file.uid,
        status: file.status
      };
      this.fileLists.push(param);
      console.log(this.fileLists, "1s");
    },
    handleRemove(file, fileList) {
      console.log(file, fileList);
      this.fileLists.pop(file.response);
      console.log(this.fileLists);
    },
    handleExceed(files, fileList) {
      //提示超出上传限制文件的个数
      this.$message.warning(
        `当前限制选择 3 个文件，本次选择了 ${
          files.length
        } 个文件，共选择了 ${files.length + fileList.length} 个文件`
      );
    },

    get() {
      console.log(this.formLabelAlign.status);
    },
    //取消编辑
    cancel() {
      this.$router.push("/dashboard/abnormityAdmin/patrolList");
    },
    assetEdits() {
      console.log(this.formLabelAlign, "this.formLabelAlign");
      // this.content.deptId = this.formLabelAlign.deptId;
      // this.content.status = this.formLabelAlign.status;
      // this.content.inspectionId = this.formLabelAlign.inspectionId;
      // if (this.fileLists.length === 0) {
      //   this.content.imageUrl = "";
      //   this.content.imageUrl2 = "";
      //   this.content.imageUrl3 = "";
      // } else if (this.fileLists.length === 1) {
      //   this.content.imageUrl = "";
      //   this.content.imageUrl2 = "";
      //   this.content.imageUrl3 = "";
      //   this.content.imageUrl = this.fileLists[0].url;
      // } else if (this.fileLists.length === 2) {
      //   this.content.imageUrl = "";
      //   this.content.imageUrl2 = "";
      //   this.content.imageUrl3 = "";
      //   this.content.imageUrl = this.fileLists[0].url;
      //   this.content.imageUrl2 = this.fileLists[1].url;
      // } else {
      //   this.content.imageUrl = this.fileLists[0].url;
      //   this.content.imageUrl2 = this.fileLists[1].url;
      //   this.content.imageUrl3 = this.fileLists[2].url;
      // }

      //   console.log(this.content.status, "this.content.status");
      //   console.log(this.content.inspectionId, "this.content.inspectionId");
      //console.log(this.content, "this.content");
      let data = {
        ...this.formLabelAlign,
        userId: localStorage.getItem("userId"),
        token: localStorage.getItem("token")
      };
      console.log(data, "data");
      assetEdit(data).then(res => {
        console.log(this.formLabelAlign, "this.formLabelAlign");
        if (res.data.desc == "SUCCESS" && res.data.resultCode == 0) {
          console.log(res.data);
          this.$message({
            showClose: true,
            message: "编辑成功！",
            type: "success",
            duration: 800
          });
          this.$router.push("/dashboard/abnormityAdmin/patrolList");
        } else {
          this.$message({
            showClose: true,
            message: "编辑失败",
            type: "error",
            duration: 800
          });
        }
      });
    }
  }
};
</script>

<style scoped>
.form {
  width: 400px;
  margin-left: 35px;
}
.title {
  font-family: PingFangSC-Regular;
  font-size: 18px;
  color: #3a3a3a;
  letter-spacing: -0.05px;
  margin-bottom: 24px;
}
.buttons {
  margin-left: 30px;
  margin-top: 20px;
}
</style>
