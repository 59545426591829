import axios from "../../utils/request";

//获取巡检记录报表分页列表
export function getList(data) {
  return axios({
    url: "/clodchainAM/web/get_device_inspection_list_page",
    method: "get",
    params: data
  });
}

//导出
export function exportData(data) {
  return axios({
    url: "/clodchainAM/web/batch_device_inspection_info",
    method: "get",
    params: data,
    responseType: "blob",
    loading: true
  });
}

//保存修改
export function assetEdit(data) {
  return axios({
    url: "/clodchainAM/web/edit_device_inspection",
    method: "post",
    data: data
  });
}
